import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

const ProductGallery = ({ products, onSelectProduct }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredProducts, setFilteredProducts] = useState(products);
    const [selectedFilter, setSelectedFilter] = useState('');
    const [gridLayout, setGridLayout] = useState(4); // Default to 4 items per row
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        let tempFiltered = searchTerm
            ? products.filter(p => p.title.toLowerCase().includes(searchTerm.toLowerCase()))
            : products;
        tempFiltered = selectedFilter
            ? tempFiltered.filter(p => p.source.toLowerCase().includes(selectedFilter.toLowerCase()))
            : tempFiltered;
        setFilteredProducts(tempFiltered);

        // Add click outside handler
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [searchTerm, products, selectedFilter]);

    const getGridClass = () => {
        switch (gridLayout) {
            case 2:
                return 'grid-cols-1 sm:grid-cols-2';
            case 3:
                return 'grid-cols-1 sm:grid-cols-2 md:grid-cols-3';
            case 4:
                return 'grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4';
            case 6:
                return 'grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6';
            default:
                return 'grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4';
        }
    };

    const sources = ['All Sources', 'Noon', 'SharafDG', 'Jumbo', 'Amazon'];
    const isMobile = window.innerWidth < 640;

    return (
        <div className="w-full max-w-8xl mx-auto py-4">
            <div className="flex flex-col sm:flex-row gap-4 mb-4 mx-auto
                // space between
                justify-between
            ">
                <input
                    type="text"
                    placeholder="Search Products"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="p-2 border rounded-lg focus:outline-none dark:bg-gray-700 dark:text-white dark:border-gray-600
                        w-full sm:w-1/2 md:w-1/3 lg:w-1/3
                    "
                />

                <div className='flex gap-2 mx-auto'>
                    <div className="relative inline-block text-left" ref={dropdownRef}>
                        <button
                            type="button"
                            className="inline-flex w-full h-full items-center
                        justify-center gap-x-1.5 rounded-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 shadow-xs ring-gray-300 ring-inset hover:bg-gray-50 dark:bg-gray-700 dark:text-white dark:ring-gray-600 dark:hover:bg-gray-600"
                            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                        >
                            {selectedFilter || 'All Sources'}
                            <svg className="-mr-1 size-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fillRule="evenodd" d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z" clipRule="evenodd" />
                            </svg>
                        </button>

                        {isDropdownOpen && (
                            <div className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md dark:bg-gray-700 ring-1 shadow-lg ring-black/5 focus:outline-none transition ease-out duration-100 transform opacity-100 scale-100">
                                <div className="py-1">
                                    {sources.map((source) => (
                                        <button
                                            key={source}
                                            className={`block w-full px-4 py-2 text-sm text-left ${selectedFilter === source
                                                ? 'bg-gray-100 text-gray-900 dark:bg-gray-600 dark:text-white'
                                                : 'text-gray-700 dark:text-gray-200 hover:bg-gray-50 dark:hover:bg-gray-600'
                                                }`}
                                            onClick={() => {
                                                setSelectedFilter(source === 'All Sources' ? '' : source);
                                                setIsDropdownOpen(false);
                                            }}
                                        >
                                            {source}
                                        </button>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>

                    {!isMobile && <div className="flex gap-2">
                        <button
                            onClick={() => setGridLayout(2)}
                            className={`p-2 border rounded-lg focus:outline-none transition-colors duration-200
                            ${gridLayout === 2
                                    ? 'bg-blue-500 text-white'
                                    : 'dark:bg-gray-700 dark:text-white dark:border-gray-600'}`}
                        >
                            2×
                        </button>
                        <button
                            onClick={() => setGridLayout(3)}
                            className={`p-2 border rounded-lg focus:outline-none transition-colors duration-200
                            ${gridLayout === 3
                                    ? 'bg-blue-500 text-white'
                                    : 'dark:bg-gray-700 dark:text-white dark:border-gray-600'}`}
                        >
                            3×
                        </button>
                        <button
                            onClick={() => setGridLayout(4)}
                            className={`p-2 border rounded-lg focus:outline-none transition-colors duration-200
                            ${gridLayout === 4
                                    ? 'bg-blue-500 text-white'
                                    : 'dark:bg-gray-700 dark:text-white dark:border-gray-600'}`}
                        >
                            4×
                        </button>
                        <button
                            onClick={() => setGridLayout(6)}
                            className={`p-2 border rounded-lg focus:outline-none transition-colors duration-200
                            ${gridLayout === 6
                                    ? 'bg-blue-500 text-white'
                                    : 'dark:bg-gray-700 dark:text-white dark:border-gray-600'}`}
                        >
                            6×
                        </button>
                    </div>}
                </div>
            </div>

            <div className={`grid ${getGridClass()} gap-6`}>
                {filteredProducts.map((product, index) => (
                    <div
                        key={index}
                        className="dark:bg-gray-700 rounded-lg shadow-lg p-4 relative cursor-pointer transform hover:scale-105 transition-transform"
                        onClick={() => navigate(`/products/${product.productID}`)}
                    >
                        <img src={product.image} alt={product.title} className="w-full h-48 object-contain rounded-t-lg mb-4" />
                        {product.currentPrice < product.originalPrice && (
                            <div className="absolute top-2 right-2 bg-red-500 text-white text-xs px-2 py-1 rounded">Sale</div>
                        )}
                        <div className="absolute top-2 left-2 bg-green-500 text-white text-xs px-2 py-1 rounded">
                            Discount: {product.discountPercentage}%
                        </div>
                        <h3 className="text-lg font-semibold mb-2 text-gray-900 dark:text-gray-200 line-clamp-2 h-14 overflow-hidden">
                            {product.title}
                        </h3>
                        {product.currentPrice < product.originalPrice ? (
                            <div className="text-red-500 dark:text-red-400 font-bold">
                                Price: <span className="line-through text-sm text-gray-500 dark:text-gray-400">
                                    {product.currency} {product.originalPrice}
                                </span>
                                <span className="ml-2">
                                    {product.currency} {product.currentPrice}
                                </span>
                            </div>
                        ) : (
                            <div className="text-blue-600 dark:text-blue-400 font-bold">
                                Price: {product.currency} {product.originalPrice}
                            </div>
                        )}
                        <div className="mt-4 text-center">
                            <a
                                href={product.url}
                                target="_blank"
                                rel="noreferrer"
                                className="px-4 py-2 bg-yellow-500 hover:bg-yellow-400 text-gray-900 font-semibold rounded transition-colors duration-200"
                            >
                                Shop Now
                            </a>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ProductGallery;
