import React, { useState, useEffect } from 'react';
import axios from 'axios';

const EmailTrackForm = ({ productID }) => {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        addEmail(email);
        setEmail('');
        setLoading(true);
    };

    const addEmail = async (email) => {
        const data = JSON.stringify({ email });
        const config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_BACKEND_API_URL}/AddUserEmail/${productID}`,
            headers: { 'Content-Type': 'application/json' },
            data: data
        };

        axios.request(config)
            .then((response) => console.log("Success!"))
            .catch((error) => console.log(error));
    };

    useEffect(() => {
        if (loading) {
            const timer = setTimeout(() => {
                setLoading(false);
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [loading]);

    return (
        <div className="max-w-xl mx-auto p-6">
            <h2 className="text-2xl font-bold text-center mb-4 text-black-800 dark:text-black-100">
                Sign up to be notified when the price drops!
            </h2>
            <form onSubmit={handleSubmit} className="flex flex-col sm:flex-row gap-4">
                <input
                    type="email"
                    placeholder="Enter Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="flex-1 p-2 border rounded-lg focus:outline-none dark:bg-gray-700 dark:text-white dark:border-gray-600"
                    required
                />
                <button
                    type="submit"
                    className="px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-500"
                >
                    {loading ? 'Adding...' : 'Add Email'}
                </button>
            </form>
        </div>
    );
};

export default EmailTrackForm;
