import React, { useState, useRef, useEffect } from 'react';

const ProductForm = ({ onAddProduct }) => {
    const [url, setUrl] = useState('');
    const [website, setWebsite] = useState('');
    const [loading, setLoading] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const websites = [
        { value: '', label: 'Select Website' },
        { value: 'noon', label: 'Noon' },
        { value: 'amazon', label: 'Amazon' },
        { value: 'sharafdg', label: 'SharafDG' },
        { value: 'jumbo', label: 'Jumbo' }
    ];

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!website) {
            alert("Please select a website to track the product from.");
            return;
        }
        onAddProduct(url, website);
        setUrl('');
        setLoading(true);
    };

    useEffect(() => {
        if (loading) {
            const timer = setTimeout(() => {
                setLoading(false);
                window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
            }, 4000);
            return () => clearTimeout(timer);
        }
    }, [loading]);

    return (
        <form onSubmit={handleSubmit} className="w-full max-w-3xl mx-auto flex flex-col sm:flex-row gap-4 p-4 dark:bg-gray-600 rounded-lg shadow-md">
            <input
                type="url"
                placeholder="Enter Product URL"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                className="flex-1 p-2 border rounded-lg focus:outline-none dark:bg-gray-700 dark:text-white dark:border-gray-600"
                required
            />

            <div className="relative inline-block text-left" ref={dropdownRef}>
                <button
                    type="button"
                    className="inline-flex w-full items-center justify-between w-[150px] gap-x-1.5 h-full rounded-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 shadow-xs ring-gray-300 ring-inset hover:bg-gray-50 dark:bg-gray-700 dark:text-white dark:ring-gray-600 dark:hover:bg-gray-600"
                    onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                >
                    {website ? websites.find(w => w.value === website)?.label : 'Select Website'}
                    <svg className="-mr-1 size-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fillRule="evenodd" d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z" clipRule="evenodd" />
                    </svg>
                </button>

                {isDropdownOpen && (
                    <div className="absolute left-0 z-10 mt-2 w-full origin-top-right rounded-md dark:bg-gray-700 ring-1 shadow-lg ring-black/5 focus:outline-none transition ease-out duration-100 transform opacity-100 scale-100">
                        <div className="py-1">
                            {websites.slice(1).map((site) => (
                                <button
                                    key={site.value}
                                    type="button"
                                    className={`block w-full px-4 py-2 text-sm text-left ${website === site.value
                                        ? 'bg-gray-100 text-gray-900 dark:bg-gray-600 dark:text-white'
                                        : 'text-gray-700 dark:text-gray-200 hover:bg-gray-50 dark:hover:bg-gray-600'
                                        }`}
                                    onClick={() => {
                                        setWebsite(site.value);
                                        setIsDropdownOpen(false);
                                    }}
                                >
                                    {site.label}
                                </button>
                            ))}
                        </div>
                    </div>
                )}
                <input type="hidden" name="website" value={website} required />
            </div>

            <button
                type="submit"
                className="px-6 py-2 bg-blue-600 hover:bg-blue-500 text-white rounded-lg transition-colors duration-200"
            >
                {loading ? <span>Loading...</span> : 'Track'}
            </button>
        </form>
    );
};

export default ProductForm;
