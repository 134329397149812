import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import ProductPriceHistory from '../components/ProductPriceHistory';
import EmailTrackForm from '../components/EmailTrackForm';
import Header from '../components/Header';

const Products = () => {
    const { productid } = useParams();
    const [product, setProduct] = useState(null);

    function GetData() {
        axios.get(`${process.env.REACT_APP_BACKEND_API_URL}/Product/${productid}`)
            .then(response => {
                console.log("FETCH ONE", response.data);
                setProduct(response.data);
            });
    }
    useEffect(() => {
        GetData();
    }, []);

    function formatDate(date) {
        const d = new Date(date);
        // convert to dd/mm/yyyy hh:mm am/pm
        return d.toLocaleString('en-GB', { day: 'numeric', month: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true });
    }

    return (
        <div className="min-h-screen bg-gradient-to-br from-gray-50 to-gray-100 dark:from-gray-800 dark:to-gray-900">
            <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8">
                {product && (
                    <div className="grid lg:grid-cols-2 gap-3 lg:gap-8 py-2 mx-auto">
                        {/* Left Column - Product Image and Main Info */}
                        <div className="space-y-3 lg:space-y-6">
                            <div className="dark:bg-gray-700 rounded-2xl lg:rounded-3xl shadow-lg overflow-hidden backdrop-blur-sm bg-opacity-90
                                w-[98%]
                            ">
                                <div className="relative group">
                                    <img
                                        src={product.image}
                                        alt={product.title}
                                        className="w-full h-[300px] sm:h-[400px] lg:h-[500px] object-contain p-4 lg:p-8 transition-transform duration-300 group-hover:scale-105"
                                    />
                                    <div className="absolute top-3 right-3 lg:top-6 lg:right-6 flex flex-col gap-2">
                                        {product.currentPrice < product.originalPrice && (
                                            <span className="bg-red-500 text-white px-3 py-1 lg:px-4 lg:py-2 rounded-full text-xs lg:text-sm font-semibold shadow-lg">
                                                Sale
                                            </span>
                                        )}
                                        <span className="bg-green-500 text-white px-3 py-1 lg:px-4 lg:py-2 rounded-full text-xs lg:text-sm font-semibold shadow-lg">
                                            {product.discountPercentage}% OFF
                                        </span>
                                    </div>
                                </div>

                                <div className="p-4 lg:p-8 border-t border-gray-100 dark:border-gray-700">
                                    <h1 className="text-xl lg:text-2xl font-bold text-gray-900 dark:text-gray-100 mb-3 lg:mb-4">
                                        {product.title}
                                    </h1>
                                    <div className="flex items-center gap-2 lg:gap-4 mb-4 lg:mb-6 ">
                                        {product.currentPrice < product.originalPrice ? (
                                            <>
                                                <span className="line-through text-gray-400 text-lg lg:text-xl">
                                                    {product.currency} {product.originalPrice}
                                                </span>
                                                <span className="text-red-500 text-2xl lg:text-3xl font-bold">
                                                    {product.currency} {product.currentPrice}
                                                </span>
                                            </>
                                        ) : (
                                            <span className="text-blue-600 text-2xl lg:text-3xl font-bold">
                                                {product.currency} {product.originalPrice}
                                            </span>
                                        )}
                                    </div>
                                    <a
                                        href={product.url}
                                        target="_blank"
                                        rel="noreferrer"
                                        className="block w-full bg-gradient-to-r from-yellow-400 to-yellow-500 hover:from-yellow-500 hover:to-yellow-600 text-white font-bold py-3 lg:py-4 px-6 lg:px-8 rounded-xl lg:rounded-2xl text-center transition-all duration-300 transform hover:-translate-y-1 hover:shadow-xl text-sm lg:text-base"
                                    >
                                        Shop Now
                                    </a>
                                </div>
                            </div>

                            {/* Price History Chart */}
                            <div className="dark:bg-gray-700 rounded-2xl lg:rounded-3xl shadow-lg p-4 lg:p-6 backdrop-blur-sm bg-opacity-90 w-[98%]" >
                                <ProductPriceHistory priceHistory={product.priceHistory} />
                            </div>
                        </div>

                        {/* Right Column - Details and Email Form */}
                        <div className="space-y-4 lg:space-y-6 w-[98%]">
                            <div className="dark:bg-gray-700 rounded-2xl lg:rounded-3xl shadow-lg p-4 lg:p-8 backdrop-blur-sm bg-opacity-90 ">
                                <h2 className="text-xl lg:text-2xl font-bold mb-4 lg:mb-6 text-gray-900 dark:text-gray-100">
                                    Product Details
                                </h2>
                                <div className="grid gap-3 lg:gap-4">
                                    {[
                                        ['Source', capitalizeFirstLetter(product.source)],
                                        ['Average Price', `${product.currency} ${product.averagePrice}`],
                                        ['Highest Price', `${product.currency} ${product.highestPrice}`],
                                        ['Lowest Price', `${product.currency} ${product.lowestPrice}`],
                                        ['Product ID', product.productID],
                                        ['Created', formatDate(product.createdAt)],
                                        ['Updated', formatDate(product.updatedAt)]
                                    ].map(([label, value]) => (
                                        <div key={label} className="flex items-center py-2 lg:py-3 border-b border-gray-200 dark:border-gray-700 last:border-0">
                                            <span className="text-sm lg:text-base text-gray-900 dark:text-gray-300">
                                                {label}
                                            </span>
                                            <span className="ml-auto text-sm lg:text-base font-medium text-gray-800 dark:text-gray-100">
                                                {value}
                                            </span>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <div className="dark:bg-gray-700 rounded-2xl lg:rounded-3xl shadow-lg p-4 lg:p-8 backdrop-blur-sm bg-opacity-90">
                                <EmailTrackForm productID={productid} />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export default Products;