// src/App.js (Updated with styled-components)
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ProductForm from './components/ProductForm';
import ProductGallery from './components/ProductGallery';
import ProductDetails from './components/ProductDetails';
import axios from 'axios';
import * as cheerio from 'cheerio';
import Header from './components/Header';
import { Footer } from './components/Footer';
// import { demoResponse } from './const';
// import { data } from './data';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './pages/Home';
import { NoPage } from './pages/NoPage';
import Products from './pages/Products';
import { DarkModeProvider } from './context/DarkModeContext';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
`;

function getCurrency(str) {
  return str.match(/[A-Z]{3}/)?.[0];
}

const App = () => {
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [responseData, setResponseData] = useState(null);
  // config env

  const API_URL = process.env.REACT_APP_BACKEND_API_URL;

  // const dataJSon = JSON.parse(data.content);

  // // console.log(JSON.parse(dataJSon.content));
  // var dataParsed = {
  //   title: dataJSon.pageProps.catalog.product.product_title,
  //   currency: getCurrency(dataJSon.pageProps.catalog.product.variants[0].offers[9].bnplMaxInstallments.tabby),
  //   regPrice: dataJSon.pageProps.catalog.product.variants[0].offers[0].price,
  //   curPrice: dataJSon.pageProps.catalog.product.variants[0].offers[0].sale_price,
  //   savings: dataJSon.pageProps.catalog.product.variants[0].offers[0].price - dataJSon.pageProps.catalog.product.variants[0].offers[0].sale_price,
  //   discountPer: 100 - (dataJSon.pageProps.catalog.product.variants[0].offers[0].sale_price / dataJSon.pageProps.catalog.product.variants[0].offers[0].price) * 100,
  //   imageURL: "https://f.nooncdn.com/p/" + dataJSon.pageProps.catalog.product.image_keys[0],
  // };

  // console.log(dataParsed);

  const addFetchProduct = async (url) => {
    let data = JSON.stringify({
      "url": url
    });

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${API_URL}/ScrapeNoon`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios.request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        console.log("Success!")
        GetProducts();
      })
      .catch((error) => {
        console.log(error);
      });

  }


  // const addProduct = async (url) => {

  //   const productID = url.split('/')[5];
  //   console.log(productID);

  //   let data = JSON.stringify({
  //     "apiKey": process.env.REACT_APP_MINESCALE_API_KEY,
  //     "requests": [
  //       {
  //         "url": "https://www.noon.com/_next/data/bigalog-2b8f9c089b0c5febb3f6e937c4f977af459426c9/uae-en/" + productID + "/p.json",
  //       }
  //     ]
  //   });


  //   let config = {
  //     method: 'post',
  //     maxBodyLength: Infinity,
  //     url: 'https://api.minescale.net',
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //     data: data
  //   };
  //   axios.request(config)
  //     .then((response) => {
  //       // Handle successful response
  //       console.log(JSON.stringify(response.data)); // Optionally log or process the data
  //       // Assuming setResponseData is a function to set state (for React) or another context
  //       setResponseData(response.data); // Set the response data in the state
  //       // processResponseData(response.data); // Process the response data
  //       processResponseData2(response.data); // Process the response data
  //     })
  //     .catch((error) => {
  //       // Handle errors
  //       console.log(error); // Log the error
  //     });

  //   async function processResponseData2(re) {
  //     const dataJSon = JSON.parse(re[0].content);

  //     let currency = getCurrency(dataJSon.pageProps.catalog.product.variants[0].offers[0].bnplMaxInstallments.tabby);
  //     let image = "https://f.nooncdn.com/p/" + dataJSon.pageProps.catalog.product.image_keys[0] + ".jpg";
  //     let title = dataJSon.pageProps.catalog.product.product_title;
  //     let originalPrice = dataJSon.pageProps.catalog.product.variants[0].offers[0].price;
  //     let currentPrice = dataJSon.pageProps.catalog.product.variants[0].offers[0].sale_price;
  //     let savings = originalPrice - currentPrice;
  //     let discountPer = 100 - (currentPrice / originalPrice) * 100;
  //     // console.log(JSON.parse(dataJSon.content));
  //     var dataParsed = {
  //       productID: productID,
  //       url: url,
  //       source: "noon",
  //       currency: currency,
  //       image: image,
  //       title: title,
  //       originalPrice: originalPrice,
  //       currentPrice: currentPrice ? currentPrice : originalPrice,
  //       savings: currentPrice ? savings : 0,
  //       discountPercentage: currentPrice ? discountPer : 0,
  //       priceHistory: [
  //         {
  //           price: currentPrice ? currentPrice : originalPrice,
  //         }
  //       ]
  //     };

  //     console.log(dataParsed);

  //     // Send to DB
  //     const response = await axios.post(`${API_URL}/CreateProduct`, dataParsed);
  //     console.log(response);

  //     // Get all products
  //     GetProducts();
  //   }

  //   function processResponseData(re) {
  //     // Asynchronous operation with responseData
  //     console.log(re);

  //     // Extract orginal price and current price
  //     const $ = cheerio.load(re[0].content);
  //     const productID = re[0].url.split('/')[5];
  //     console.log(productID);

  //     const title = $(`h1[data-qa="pdp-name-${productID}"]`).text();
  //     const currency = $(`.priceWas`).text().split(' ')[0];
  //     const regPrice = $(`.priceWas`).text().split(' ')[1];
  //     const curPrice = $(`.priceNow`).text().split(' ')[0].split(" ")[1];
  //     const savings = $(`.priceSaving`).text().split(" ")[1]
  //     const discountPer = $(`.priceSaving`).text().split(" ")[2].split(" ")[0];
  //     const imageURL = $('.sc-d8caf424-2.fJBKzl img').attr('src');
  //     console.log("title", title);
  //     console.log("currency", currency);
  //     console.log("regPrice", regPrice);
  //     console.log("curPrice", curPrice);
  //     console.log("savings", savings);
  //     console.log("discountPer", discountPer);
  //     console.log("imageURL", imageURL);
  //   }

  //   // var responseData2 = demoResponse;
  //   // processResponseData(responseData2);

  //   // const productData = {
  //   //   url: url,
  //   //   currentPrice: demoResponse,
  //   //   highestPrice: Math.random() * 100,
  //   //   lowestPrice: Math.random() * 100,
  //   //   averagePrice: Math.random() * 100
  //   // };

  //   // setProducts([...products, productData]);
  // };

  const GetProducts = async () => {
    const response = await axios.get(`${API_URL}/AllProducts`);
    console.log(response.data);
    setProducts(response.data);
  }

  useEffect(() => {
    GetProducts();
  }, []);

  const selectProduct = (product) => {
    setSelectedProduct(product);
  };

  return (
    <DarkModeProvider>
      <div className="min-h-screen bg-white dark:bg-gray-900 transition-colors duration-200">
        <BrowserRouter>
          <Header />
          <Routes>
            <Route path="/" element={<Home />}>
            </Route>
            <Route path="/home" element={<Home />} />
            <Route path="*" element={<NoPage />} /> {/* Catch-all for 404 */}
            <Route path="/products/:productid" element={<Products />} />
          </Routes>
          <Footer />
        </BrowserRouter>
      </div>
    </DarkModeProvider>
  );
};

export default App;


// <>
// <Header />
// <Container>
//   <ProductForm onAddProduct={addFetchProduct} />
//   <ProductGallery products={products} onSelectProduct={selectProduct} />
//   <ProductDetails product={selectedProduct} />
// </Container>
// <Footer />
// </>