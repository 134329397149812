import React from 'react';
import { useDarkMode } from '../context/DarkModeContext';
import './Header.css'

const Header = () => {
    const { darkMode, toggleDarkMode } = useDarkMode();

    return (
        <header className="flex justify-between items-center p-4 light:bg-red-100 text-gray-900 dark:text-white shadow-md transition-colors duration-200"
            style={{ backgroundColor: darkMode ? '#030a16' : '#e3e4e6' }}
        >
            <a href="/" className="flex items-center">
                <img
                    src={!darkMode ? "/Price Patrol Logo.png" : "/Price Patrol Logo White.png"}
                    alt="Logo"
                    className="h-10"
                />
            </a>
            <button
                onClick={toggleDarkMode}
                className="px-4 py-2 rounded-lg bg-gray-100 dark:bg-gray-700 hover:bg-gray-200 dark:hover:bg-gray-600 transition-colors duration-200"
                aria-label="Toggle dark mode"
            >
                {darkMode ? (
                    <span className="flex items-center">
                        <svg className="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20">
                            <path d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z" />
                        </svg>
                        Light Mode
                    </span>
                ) : (
                    <span className="flex items-center">
                        <svg className="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20">
                            <path d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z" />
                        </svg>
                        Dark Mode
                    </span>
                )}
            </button>
        </header>
    );
};

export default Header;
